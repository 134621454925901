@import '../../../../scss/theme-bootstrap';

.landing-page--kate-hudson {
  .video-library-formatter {
    margin-bottom: 20px;
    @include breakpoint($landscape-up) {
      margin-bottom: 50px;
    }
    &:after {
      margin-top: 55px;
    }
  }
  .collapsible-block.active {
    padding-bottom: 40px;
  }
}

.landing-page {
  &__section:last-child {
    .content-block-formatter {
      margin-bottom: 0;
    }
  }
}
